import type { AppProps } from 'next/app';
import 'react-multi-carousel/lib/styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../styles/globals.css';

import { queryClient } from '@/utils/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import CookieConsent from 'react-cookie-consent';
import AppWrapper from '../components/AppWrapper';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <Component {...pageProps} />
          <CookieConsent
            location="bottom"
            buttonText="Rozumiem"
            cookieName="KFDCookies"
            style={{ background: '#2B373B' }}
            buttonStyle={{
              backgroundColor: '#141414',
              color: '#fff',
              fontSize: '14px',
            }}
            expires={150}
          >
            Ta strona korzysta z ciasteczek (cookies), dzięki którym nasz serwis
            może działać lepiej.
          </CookieConsent>
        </AppWrapper>
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
}
