export const modalIds = {
  basic: 'basic-modal',
};

// Link do API, formularz kontaktowy wysyła dane na endpoint /contact
// {
//   name: string,
//   phone: string,
//   email: string,
// }

export const API_URL = 'https://api.smth.com/';

// zmienna postów na blogu:
// id: number, img: string, title: string, desc: string, articles: {title: string, desc: HTML, image: string}[]
// posty można dodawać analogicznie do tych które już są dodane, zdjęcia do postów dodajemy do folderu public i wyciągamy je tak jak na przykładzie niżej

export const blogPosts: {
  id: number;
  img: string;
  title: string;
  desc: string;
  articles: { title: string; desc: any; image?: string }[];
}[] = [
  {
    id: 1,
    img: '/post-2.png',
    title: 'Sauny w twoim ogrodzie - luksus i relaks na wyciągnięcie ręki.',
    desc: 'Wybór odpowiedniej sauny do ogrodu może przynieść niesamowite korzyści dla zdrowia i samopoczucia. Nasza oferta saun ogrodowych to połączenie nowoczesnych technologii z najwyższą jakością materiałów.',
    articles: [
      {
        title: 'Funkcjonalność i zalety',
        desc: (
          <div>
            1. Nasze sauny są zaprojektowane z myślą o maksymalnym komforcie.
            Wysokiej jakości drewno, ergonomiczne siedzenia i zaawansowane
            systemy grzewcze zapewniają niepowtarzalne doświadczenia.
            <br />
            2. Regularne korzystanie z sauny wspomaga krążenie, detoksykację
            organizmu oraz redukcję stresu. Nasze produkty pomagają w
            regeneracji ciała po ciężkim dniu.
            <br />
            3. Sauny dostępne w naszej ofercie charakteryzują się nowoczesnym
            designem, który doskonale komponuje się z każdym ogrodem. Możliwość
            personalizacji pozwala na dopasowanie wyglądu sauny do
            indywidualnych preferencji.
          </div>
        ),
      },
      {
        title: 'Podsumowanie',
        desc: (
          <div>
            Inwestycja w saunę ogrodową to krok w kierunku zdrowego trybu życia
            i luksusu. Skontaktuj się z nami, aby uzyskać fachowe porady i
            znaleźć idealną saunę dla siebie.
          </div>
        ),
      },
    ],
  },
  {
    id: 2,
    img: '/post-1.png',
    title: 'Spa & wellness w twoim ogrodzie - twoje prywatne miejsce relaksu',
    desc: 'Marzysz o strefie relaksu w swoim ogrodzie? Nasza oferta spa & wellness pozwala na stworzenie prywatnej oazy spokoju. Od luksusowych jacuzzi po funkcjonalne kabiny masażu – mamy wszystko, czego potrzebujesz.',
    articles: [
      {
        title: 'Funkcjonalność i zalety',
        desc: (
          <div>
            1. <span className="font-bold">Relaks i regeneracja</span>: Nasze
            produkty spa & wellness zostały zaprojektowane, aby dostarczać
            maksymalny relaks. Hydromasaż, aromaterapia i chromoterapia to tylko
            niektóre z funkcji, które oferujemy.
            <br />
            2. <span className="font-bold">Wysoka jakość materiałów</span>:
            Wykorzystujemy tylko najwyższej jakości materiały, które są trwałe i
            odporne na warunki atmosferyczne. To gwarancja długowieczności i
            niezawodności.
            <br />
            3. <span className="font-bold">Łatwość użytkowania</span>:
            Intuicyjne panele sterujące i łatwa konserwacja sprawiają, że nasze
            produkty są przyjazne dla użytkownika. Bezproblemowa obsługa pozwala
            na pełne korzystanie z możliwości, jakie daje prywatne spa.
          </div>
        ),
      },
      {
        title: 'Podsumowanie',
        desc: (
          <div>
            Tworzenie strefy spa & wellness w ogrodzie to inwestycja w komfort i
            zdrowie. Skontaktuj się z nami, aby uzyskać profesjonalne doradztwo
            i wybrać najlepsze rozwiązanie dla siebie.
          </div>
        ),
      },
    ],
  },
  {
    id: 3,
    img: '/post-4.png',
    title: 'Balustrady ogrodowe - bezpieczeństwo i elegancja',
    desc: 'Balustrady ogrodowe to nie tylko kwestia bezpieczeństwa, ale także estetyki. Nasza oferta balustrad ogrodowych łączy w sobie trwałość, funkcjonalność i elegancki wygląd, idealnie dopasowując się do każdego rodzaju przestrzeni.',
    articles: [
      {
        title: 'Funkcjonalność i zalety',
        desc: (
          <div>
            1. Wykonane z najlepszych materiałów, nasze balustrady zapewniają
            maksymalne bezpieczeństwo i stabilność. Są idealne dla tarasów,
            balkonów i schodów.
            <br />
            2. Oferujemy szeroką gamę stylów i wykończeń, od nowoczesnych po
            klasyczne. Każda balustrada może być dostosowana do indywidualnych
            potrzeb i preferencji.
            <br />
            3. Nasze balustrady są łatwe w montażu i nie wymagają skomplikowanej
            konserwacji. Trwałe materiały zapewniają długą żywotność i odporność
            na warunki atmosferyczne.
          </div>
        ),
      },
      {
        title: 'Podsumowanie',
        desc: (
          <div>
            Wybór odpowiednich balustrad ogrodowych to inwestycja w
            bezpieczeństwo i wygląd twojej przestrzeni. Skontaktuj się z nami,
            aby uzyskać profesjonalne porady i wybrać idealne rozwiązanie.
          </div>
        ),
      },
    ],
  },
  {
    id: 4,
    img: '/post-3.png',
    title: 'Stylowe wnętrza z produktami ogrodowymi',
    desc: 'Nasza oferta produktów ogrodowych nie kończy się na zewnątrz – wiele z nich doskonale sprawdza się również we wnętrzach. Stylowe meble, dekoracje i akcesoria ogrodowe mogą stać się integralną częścią twojego domu.',
    articles: [
      {
        title: 'Funkcjonalność i zalety',
        desc: (
          <div>
            1. <span className="font-bold">Uniwersalność zastosowań</span>:
            Wiele naszych produktów, takich jak meble ogrodowe, można z
            powodzeniem używać wewnątrz. Tworzą one unikalny styl i dodają
            wnętrzom charakteru.
            <br />
            2. <span className="font-bold">Trwałość i jakość</span>: Wykonane z
            trwałych materiałów, nasze produkty są odporne na uszkodzenia i
            łatwe w utrzymaniu. To inwestycja na lata.
            <br />
            3. <span className="font-bold">Estetyka i design</span>: Oferujemy
            produkty w różnych stylach i kolorach, które można dopasować do
            każdego wnętrza. Nasze meble i dekoracje łączą w sobie
            funkcjonalność z eleganckim wyglądem
          </div>
        ),
      },
      {
        title: 'Podsumowanie',
        desc: (
          <div>
            Stworzenie stylowego wnętrza z wykorzystaniem produktów ogrodowych
            to sposób na oryginalność i komfort. Skontaktuj się z nami, aby
            uzyskać fachowe doradztwo i znaleźć najlepsze rozwiązania dla
            swojego domu.
          </div>
        ),
      },
    ],
  },
  {
    id: 5,
    img: '/post-5.jpg',
    title:
      'Szklane Balustrady ClickitUp® - innowacja, bezpieczeństwo i design w jednym',
    desc: 'W świecie architektury i designu wnętrz coraz większą popularność zyskują szklane balustrady. Nie tylko dodają one elegancji i nowoczesności przestrzeniom, ale także zapewniają funkcjonalność i bezpieczeństwo. Wśród dostępnych na rynku rozwiązań wyróżnia się ClickitUp® – szklana balustrada o regulowanej wysokości, która łączy w sobie innowacyjność, estetykę oraz najwyższe standardy bezpieczeństwa.',
    articles: [
      {
        title: 'Dlaczego warto wybrać szklane balustrady ClickitUp®?',
        desc: (
          <div>
            1.{' '}
            <span className="font-bold">Regulowana wysokość dla komfortu</span>
            , która jest unikatową cechą ClickitUp® jest możliwość dostosowania
            wysokości balustrady do aktualnych potrzeb. Dzięki elastycznej
            osłonie, w mgnieniu oka przekształcisz swoją przestrzeń z otwartej
            na otoczenie w przytulne i chronione miejsce, idealne na wietrzne
            dni.
            <br />
            2. <span className="font-bold">Nieskazitelny design</span> dzięki
            najwyższej jakości materiałów, balustrady ClickitUp® prezentują się
            niezwykle stylowo. Przejrzyste szkło dodaje lekkości i
            nowoczesności, jednocześnie nie zakłócając widoku z tarasu czy
            balkonu
            <br />
            3.{' '}
            <span className="font-bold">
              Bezpieczeństwo na pierwszym miejscu
            </span>
            , każde produkty ClickitUp® poddawane są rygorystycznym testom
            zgodnie z normą EN 12600:2002, co gwarantuje ich wytrzymałość na
            duże uderzenia. Dzięki temu masz pewność, że Twoja balustrada nie
            tylko pięknie wygląda, ale także chroni Ciebie i Twoich bliskich.
            <br />
            4. <span className="font-bold">Zrównoważona produkcja</span>, dzięki
            temu, że nasze balustrady powstają w szwedzkiej fabryce w Halmstad,
            gdzie kładziemy nacisk na zrównoważony rozwój. Wykorzystujemy
            materiały w pełni nadające się do recyklingu, dbając o środowisko
            naturalne.
          </div>
        ),
      },
      {
        title: 'CiUFlex – Nowa era balustrad szklanych',
        desc: (
          <div>
            Oprócz standardowego modelu ClickitUp®, w naszej ofercie pojawiła
            się nowość – CiUFlex. To innowacyjne rozwiązanie pozwala na
            zamówienie części stałej i wysuwanej w dowolnym momencie. Dzięki
            temu masz pełną kontrolę nad funkcjonalnością swojej balustrady,
            dostosowując ją do zmieniających się potrzeb.
          </div>
        ),
      },
      {
        title: 'Zastosowanie w różnych przestrzeniach',
        desc: (
          <div>
            Balustrady ClickitUp® doskonale sprawdzają się zarówno na tarasach,
            jak i balkonach. Dla tych, którzy chcą dodatkowo zabezpieczyć swoje
            balkony, oferujemy wersję ClickitUpInside, montowaną po wewnętrznej
            stronie istniejących balustrad.
          </div>
        ),
      },
      {
        title: 'Podsumowanie',
        desc: (
          <div>
            Wybierając szklane balustrady ClickitUp®, inwestujesz w produkt,
            który łączy w sobie nowoczesny design, funkcjonalność oraz
            bezpieczeństwo. Nasza oferta dostosowana jest do różnorodnych
            potrzeb, gwarantując satysfakcję na lata. Jeśli chcesz dowiedzieć
            się więcej lub otrzymać wycenę dostosowaną do Twoich wymagań,
            wypełnij formularz dostępny na naszej stronie. Zapraszamy do
            współpracy!
          </div>
        ),
      },
    ],
  },
  {
    id: 6,
    img: '/post-6.jpg',
    title:
      'CiUFixed – Elegancja połączona z klasą dla najbardziej wymagających warunków!',
    desc: 'Szklane balustrady stały się synonimem nowoczesności i elegancji w architekturze. Wśród dostępnych na rynku propozycji wyróżnia się CiUFixed – stała balustrada szklana, która zachwyca swoim minimalistycznym designem i funkcjonalnością. Jeśli marzysz o przestrzeni pełnej światła, która jednocześnie zapewni bezpieczeństwo, CiUFixed jest idealnym rozwiązaniem dla Ciebie.',
    articles: [
      {
        title: 'Czym jest CiUFixed?',
        desc: (
          <div>
            CiUFixed to stała balustrada szklana, która charakteryzuje się
            eleganckim wyglądem, doskonałym wykończeniem i solidnością
            konstrukcji. Wykonana z najwyższej jakości materiałów, stanowi
            idealne dopełnienie zarówno nowoczesnych, jak i klasycznych
            aranżacji.
          </div>
        ),
      },
      {
        title: 'Dlaczego warto wybrać szklane balustrady ClickitUp®?',
        desc: (
          <div>
            1. Przejrzyste szkło w połączeniu z minimalistycznymi elementami
            konstrukcyjnymi sprawia, że balustrada CiUFixed dodaje lekkości
            każdej przestrzeni, nie zakłócając jej naturalnego charakteru.
            <br />
            2. Balustrady CiUFixed doskonale sprawdzają się na tarasach,
            balkonach oraz wewnątrz budynków. Dzięki możliwości dostosowania do
            różnych podłoży, stanowią uniwersalne rozwiązanie dla każdego
            projektu.
            <br />
            3. Produkty CiUFixed są poddawane rygorystycznym testom
            wytrzymałościowym, co gwarantuje ich bezpieczeństwo w codziennym
            użytkowaniu. Solidna konstrukcja zapewnia długowieczność i odporność
            na warunki atmosferyczne.
            <br />
            4. Dla tych, którzy w przyszłości chcieliby rozszerzyć
            funkcjonalność swojej balustrady, oferujemy CiUFlex - wariant z
            możliwością dodania ruchomej górnej szyby, zapewniającej dodatkową
            osłonę przed wiatrem.
          </div>
        ),
      },

      {
        title: 'Idealne połączenie z innymi produktami',
        desc: (
          <div>
            Balustrady CiUFixed doskonale komponują się z innymi naszymi
            produktami, takimi jak ClickitUp®. Możesz tworzyć spójne aranżacje,
            łącząc stałe i regulowane balustrady, dostosowując przestrzeń do
            swoich indywidualnych potrzeb.
          </div>
        ),
      },
      {
        title: 'Podsumowanie',
        desc: (
          <div>
            Jeśli poszukujesz stałej balustrady szklanej, która łączy w sobie
            elegancję, funkcjonalność i bezpieczeństwo, CiUFixed jest dla Ciebie
            idealnym wyborem. Zachęcamy do zapoznania się z naszą ofertą i
            skorzystania z formularza wyceny dostępnego na stronie. Nasz zespół
            z przyjemnością pomoże w wyborze najlepszego rozwiązania dla Twojego
            domu.
          </div>
        ),
      },
    ],
  },
  {
    id: 7,
    img: '/post-7.jpg',
    title: 'Zrównoważony rozwój w produkcji szklanych balustrad',
    desc: 'W dobie rosnącej świadomości ekologicznej coraz więcej osób zwraca uwagę na to, skąd pochodzą produkty i jak są wytwarzane. W Ergosafe AB doskonale rozumiemy te potrzeby, dlatego nasze szklane balustrady, takie jak ClickitUp®, CiUFixed czy CiUFlex, produkowane są z myślą o zrównoważonym rozwoju, jakości i bezpieczeństwie.',
    articles: [
      {
        title: 'Szwedzka produkcja z sercem',
        desc: (
          <div>
            Nasza fabryka zlokalizowana jest w malowniczym Halmstad w Szwecji.
            To tutaj, w nowoczesnych pomieszczeniach produkcyjnych, powstają
            nasze balustrady szklane. Dzięki lokalnej produkcji mamy pełną
            kontrolę nad każdym etapem wytwarzania, co przekłada się na
            najwyższą jakość produktów.
          </div>
        ),
      },
      {
        title: 'Materiały przyjazne środowisku',
        desc: (
          <div>
            Stawiamy na materiały w pełni nadające się do recyklingu. Wybierając
            nasze balustrady, inwestujesz w produkty, które nie obciążają
            środowiska naturalnego. Dbamy o to, aby surowce były pozyskiwane w
            sposób odpowiedzialny, szanując zasoby naszej planety.
          </div>
        ),
      },

      {
        title: 'Ciągłe doskonalenie i innowacja',
        desc: (
          <div>
            Nasza misja to nieustanne dążenie do doskonałości. Inwestujemy w
            badania i rozwój, aby dostarczać innowacyjne rozwiązania, takie jak
            ClickitUp® – regulowana szklana balustrada, czy CiUFlex – produkt z
            możliwością rozbudowy o ruchomą górną szybę. Dzięki temu nasze
            produkty nie tylko spełniają, ale często przewyższają oczekiwania
            klientów.
          </div>
        ),
      },
    ],
  },
];
