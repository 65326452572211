import { Field } from 'formik';

export function FormikFormField(props: {
  placeholder: string;
  value?: string;
  name?: string;
  class?: string;
  type?: string;
}) {
  return (
    <Field
      name={props.name}
      type={props.type ?? 'text'}
      placeholder={props.placeholder}
      value={props.value}
      className={`bg-transparent underline-none border-r-0 border-l-0 border-t-0 border-[#969696] placeholder-[#969696] p-2 outline-none ${props.class}`}
    />
  );
}
