import { modalIds } from '@/constants';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikFormField } from './FormikFormField';
import API from '@/api';
import { toast } from 'react-toastify';

function Line(props: { title: string; order: number }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="w-[41px] h-[41px] flex flex-col justify-center items-center rounded-full bg-black">
        <div className="text-[20px] text-white font-extrabold">
          {props.order}
        </div>
      </div>
      <div className="font-bold text-[20px] text-black[#9BC918]">
        {props.title}
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Imię i nazwisko są wymagane'),
  phone: Yup.string().required('Numer telefonu jest wymagany'),
  email: Yup.string().email().required('Email jest wymagany'),
  agreements: Yup.boolean().oneOf([true], 'Zgody są wymagane'),
});

export function BasicModal() {
  return (
    <dialog id={modalIds.basic} className="modal !border-0 backdrop-blur-md">
      <div className="modal-box !w-[80%] lg:!w-[91%]">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <Formik
          initialValues={{
            name: '',
            phone: '',
            email: '',
            agreements: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await toast.promise(
              API.post('contact/', {
                name: values.name,
                phone: values.phone,
                email: values.email,
              }),
              {
                pending: 'Wysyłanie...',
                success: 'Wysłano e-mail',
                error: 'Wystąpił błąd przy wysyłaniu.',
              },
            );
          }}
        >
          {({ values, errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className={`flex flex-col gap-4`}>
              <div className={`flex flex-col gap-4`}>
                <Line title="Wprowadź dane" order={1} />
                <div className="grid grid-cols-1 gap-4">
                  <FormikFormField
                    placeholder="Imię"
                    name="name"
                    value={values.name}
                  />
                  <FormikFormField
                    placeholder="E-Mail"
                    name="email"
                    value={values.email}
                  />
                  <FormikFormField
                    placeholder="Numer telefonu"
                    name="phone"
                    value={values.phone}
                  />
                </div>
                <Line title="Zaakceptuj zgody" order={2} />
                <div className="grid grid-cols-1 gap-4">
                  <label className="text-[12px]">
                    <Field type="checkbox" name="agreements" />
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                    KFD reprezentowany przez: w celu i w zakresie niezbędnym do
                    realizacji obsługi niniejszego zgłoszenia. Zapoznałem się z
                    treścią informacji o sposobie przetwarzania moich danych
                    osobowych udostępnionych pod domeną{' '}
                    <a href="/rodo" target="_blank">
                      kfd/rodo
                    </a>
                  </label>
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="text-red-500 text-xs">
                    <ul>
                      {Object.values(errors).map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <button
                  type="submit"
                  className="rounded-[12px] font-bold text-white bg-black border-none outline-none h-[42px] max-w-[200px] cursor-pointer"
                >
                  Wyślij
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </dialog>
  );
}
