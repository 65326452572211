import { AxiosInstance } from 'axios';

import { API_URL } from '@/constants';
import axios from 'axios';
import { getToken } from './utils/auth';

export function ensureHttpsProtocol(url: string) {
  if (url.startsWith('http:')) {
    return url.replace(/^http:/, 'https:');
  } else {
    return url;
  }
}

export const API: AxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

API.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default API;
